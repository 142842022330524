@import "../../Style/colors.scss";

.gift-cards,
.gift-cards-submit {
	width: calc(100% - 70px);
	max-width: 400px;
	margin: 20px auto 40px auto;

	img {
		width: 100%;
	}

	h2 {
		text-align: left;
		margin: 30px 0px 5px 0px;
		font-size: 1.5rem;
		text-transform: uppercase;
		font-weight: bold;
	}
	p {
		font-size: 1rem;
		line-height: 1.4rem;
		margin: 15px 10px;
	}

	.onedash-form-component:not(.onedash-switch-container) {
		padding: 10px 0px;
		margin: 15px 0px;
		width: 100%;
	}

	.steps {
		&.step-1 {
			.step:nth-child(1) {
				display: block;
			}
		}
		&.step-2 {
			.step:nth-child(2),
			.step:nth-child(1) {
				display: block;
			}
		}
		&.step-3 {
			.step:nth-child(2),
			.step:nth-child(1),
			.step:nth-child(3) {
				display: block;
			}
			.btn-primary {
				display: block;
			}
		}
		.btn-primary {
			display: none;
		}

		.cards {
			margin-left: 10px;
			button {
				background: none;
				padding: 7.5px 10px;
				border-radius: 10px;
				margin: 5px;
				font-weight: bold;
				border: 2px solid nth($featureColors, 1);
				transition: 0.3s all ease-in-out;
				font-size: 1rem;
				&:focus {
					outline: none;
					border-color: black;
				}

				@media (min-width: 720px) {
					&:hover {
						opacity: 0.7;
					}
				}
				&.active {
					color: white;
					background: nth($featureColors, 1);
					border: 2px solid nth($featureColors, 1);
				}
			}
		}

		.cart {
			h3 {
				margin-left: 10px;
				margin-top: 20px;
			}
			ul {
				margin: 10px 10px 5px 10px;
			}
			.total {
				display: flex;
				flex-direction: row-reverse;
				text-decoration: underline;
				font-weight: 900;
				font-size: 1.2rem;
				border-top: 2px dashed gray;
				margin: 0px 10px;
				padding-top: 5px;
				p {
					margin: 0px;
				}
			}
			li {
				display: flex;

				.price {
					flex-grow: 1;
					display: flex;
					button {
						background: none;
						border: none;
						color: #e66f59;
						font-size: 1rem;
						margin-left: 10px;
					}
				}
				.total-price {
					flex-basis: 60px;
					font-weight: bold;
					text-align: right;
				}
				.amount {
					text-align: right;
				}
				p {
					margin: 0px;
				}
			}
		}

		.step {
			display: none;
		}
	}
}
