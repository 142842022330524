.mediathek-wrapper {
	display: flex;
	justify-content: center;
	width: 100%;
	.tv-icon {
		width: 200px;
		position: absolute;
		margin-top: -50px;
		z-index: 2;
		margin-left: calc(50% - 130px);
	}

	.tags-wrapper {
		overflow-x: auto;
		@media (max-width: 720px) {
			width: calc(100% + 70px);
			margin-left: -35px;
		}
		.tags {
			flex-wrap: nowrap;
			justify-content: flex-start;

			.tag:last-of-type {
				margin-right: 100px;
			}
			.tag {
				flex-grow: 1;
				white-space: nowrap;
			}
		}
	}
	.tags {
		margin: 10px 5px;
		display: flex;
		flex-wrap: wrap;
		overflow-x: auto;
		padding-bottom: 10px;
		.placeholder {
			height: 20px;
			flex-shrink: 0;
			flex-basis: 30px;
		}

		.tag {
			cursor: pointer;
			padding: 7.5px 15px;
			text-transform: uppercase;
			border: 2px solid var(--feat-2);
			color: var(--text);
			font-weight: bold;

			margin: 2.5px 5px;
			border-radius: 20px;

			&.selected {
				background: var(--feat-2);
				color: white;
			}
			transition: 0.15s opacity ease-in-out;
			&:hover {
				opacity: 0.7;
			}
		}
	}

	.mediathek {
		padding: 0px 30px;
		max-width: 600px;
		flex-grow: 1;
		width: calc(100% - 60px);
		position: relative;
		.movie-toolbar {
			display: flex;
			align-items: center;
			.onedash-input {
				flex-grow: 1;
			}
			.onedash-button {
				display: flex;
				justify-content: center;
				align-items: center;
				width: auto;
				width: 25px;
				height: 25px;
				margin: 0px;
				padding: 0px;
				border: none;
				background: none;
				transition: 0.15s all ease-in-out;
				&:hover {
					opacity: 0.7;
				}
			}
		}
		.infinite-scroll-component {
			overflow: hidden !important;
			padding-bottom: 50px;
			.end-text {
				margin-top: 50px;
				color: var(--feat-3);
				font-weight: bold;
				font-size: 1.2rem;

				.blue {
					color: var(--feat-1);
				}
			}
		}

		.not-found {
			margin-top: 50px;
			font-size: 1.3rem;
		}

		.mediathek-spinner {
			margin-top: 100px;
		}

		.movie-list {
			margin-top: -20px;
			width: calc(100% + 30px);
			margin-left: -15px;
			.movie {
				margin: 15px 0px;
				padding: 10px 20px 0px 20px;
				position: relative;
				border-bottom: 1px solid var(--light-grey);

				.movie-thumbnail {
					display: block;
					position: relative;
					img,
					.youtube-player {
						width: 100%;
						border-radius: 20px;
						box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);

						.react-player__preview {
							border-radius: 20px;
							padding-bottom: 56.25%;
							height: 0 !important;
							.react-player__shadow {
								display: none !important;
							}
						}
						iframe {
							border-radius: 20px;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						}
						& > div {
							position: relative;
							padding-bottom: 56.25%;
							padding-top: 0;
							height: 0;
							overflow: hidden;
						}
					}

					.duration {
						position: absolute;
						bottom: 15px;
						z-index: 1;
						background: rgba(0, 0, 0, 0.3);
						backdrop-filter: blur(2px);
						padding: 5px 15px;
						color: white;
						border-radius: 20px;
						left: 10px;
					}
				}

				.description {
					margin: 5px 10px;
					&.collapsed {
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3; /* number of lines to show */
						-webkit-box-orient: vertical;
					}
				}
				h2 {
					text-align: left;
					font-size: 1.5rem;
					margin: 20px 10px 0px 10px;
					font-weight: 400;
				}
			}
		}
	}
}
