@import url("https://fonts.googleapis.com/css?family=Raleway:400,500,700,800,900&display=swap");
@import "colors.scss";

* {
	margin: 0;
	padding: 0;
	font-family: "Raleway", sans-serif;
	-webkit-tap-highlight-color: transparent;
	-webkit-appearance: none;
}
html {
	overflow: hidden;
}
body {
	overflow-y: auto;
}
body,
html {
	background: $backgroundColor;
	color: $textColor;
	font-size: 14px;
	overflow-x: hidden;
	height: 100%;

	&.scrollingDisabled {
		overflow: hidden !important;
	}
}

li {
	list-style: none;
}

button,
a {
	cursor: pointer;
}

.bold {
	font-weight: bold;
}
.text-center {
	text-align: center;
}
$circle-length: 151px;
$check-length: 36px;

@keyframes scaleAnimation {
	0% {
		opacity: 0;
		transform: scale(1.5);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes drawCircle {
	0% {
		stroke-dashoffset: $circle-length;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes drawX {
	0% {
		stroke-dashoffset: $check-length;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes defaultFadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes defaultFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@media (min-width: 720px) {
	.app-bg {
		display: none;
	}
}

#root .about-text {
	max-width: 600px;

	@media (min-width: 720px) {
		h2 {
			margin-top: -50px !important;
		}
	}
	h2 {
		color: var(--feat-1);
		margin: 0px;
		margin-bottom: 40px;

		font-weight: bold;
		font-size: 1.7rem;
		text-transform: uppercase;
	}
	h3 {
		color: var(--feat-2);
		font-size: 1.5rem;
		margin-top: 25px;
		margin-bottom: 0;

		&:first-of-type {
			margin-top: 0px;
		}
	}
	h4 {
		color: var(--feat-3);
		margin: 20px 0 0 0;
		font-size: 1.1rem;
	}
	p {
		font-size: 1rem;
		line-height: 150%;
		margin: 7.5px 5px;
	}
	ul {
		padding-left: 20px;
		li {
			list-style-type: square;
			margin: 5px 0;
		}
	}
}

#errorAnimationCircle {
	stroke-dasharray: $circle-length $circle-length;
	stroke: var(--invalid);
}

#errorAnimationX {
	stroke-dasharray: $check-length $check-length;
	stroke: var(--invalid);
}

#errorAnimationResult {
	fill: var(--invalid);
	opacity: 0;
}

#errorAnimation.animated {
	animation: 1s ease-out 0s 1 both scaleAnimation;

	#errorAnimationCircle {
		animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both defaultFadeOut;
	}

	#errorAnimationX {
		animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawX, 0.3s linear 0.9s 1 both defaultFadeOut;
	}

	#errorAnimationResult {
		animation: 0.3s linear 0.9s both defaultFadeIn;
	}
}

@keyframes scaleAnimation {
	0% {
		opacity: 0;
		transform: scale(1.5);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes drawCircle {
	0% {
		stroke-dashoffset: $circle-length;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes drawCheck {
	0% {
		stroke-dashoffset: $check-length;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes defaultFadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes defaultFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

#successAnimationCircle {
	stroke-dasharray: $circle-length $circle-length;
	stroke: var(--success);
}

#successAnimationCheck {
	stroke-dasharray: $check-length $check-length;
	stroke: var(--success);
}

#successAnimationResult {
	fill: var(--success);
	opacity: 0;
}

#successAnimation.animated {
	animation: 1s ease-out 0s 1 both scaleAnimation;

	#successAnimationCircle {
		animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both defaultFadeOut;
	}

	#successAnimationCheck {
		animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both defaultFadeOut;
	}

	#successAnimationResult {
		animation: 0.3s linear 0.9s both defaultFadeIn;
	}
}

.data-proceed {
	.redirect-to-home {
		background: nth($featureColors, 3);
		color: white;
		text-transform: uppercase;
		padding: 15px 40px;
		border: none;
		border-radius: 30px;
		font-size: 1.2rem;
	}
	.style-vg-twenty.spinner-container.visible {
		width: 150px;
		height: 150px;
	}

	.btn-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 50px;
	}
	.error,
	.success {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.response-message-title h2 {
		color: white;
		padding: 10px 50px;
		font-size: 1.5rem;
		margin-bottom: 10px;
		text-align: center;
	}
	.response-message-text {
		color: white;
		font-size: 1.2rem;
		max-width: 400px;
		padding: 10px 50px;
		text-align: center;
	}
}

.hidden {
	display: none;
}
#root .submit-btn {
	text-transform: uppercase;
	font-size: 1.2rem;
}
.error {
	background: var(--primaryLight);
	display: flex;
	align-items: center;
	border-radius: 10px;
	margin-top: 30px;
	line-height: 1.2rem;
	transition: 0.3s all linear;
	overflow: hidden;
	max-height: 0px;

	&.visible {
		max-height: 150px;
	}
	i {
		color: var(--primary);
		margin-left: 20px;
	}
	.text {
		margin: 20px;
		color: var(--primary);
	}
}

#root .onedash-popover-wrapper.submit-popup {
	h1 {
		text-align: center;
		font-size: 1.3rem;
		line-height: 115%;
		margin-top: -50px;
	}
	.wallet-link {
		margin-left: auto;
		width: 150px;
		display: block;
		margin-right: auto;
		margin-top: 20px;
		img {
			width: 100%;
		}
	}
	p {
		text-align: center;
		margin-top: 20px;
	}
	svg {
		margin-top: -30px;
	}
}
#root .onedash-popover-wrapper {
	font-weight: normal;

	h1 {
		margin-top: 20px;
	}
}

#root .onedash-dialog {
	a {
		word-wrap: break-word;
	}
}
