@import "../../Style/colors.scss";

h2 {
	margin: 0px 30px;
	text-align: center;
	font-size: 1.5rem;
	font-weight: 500;
	color: #333333;
	margin-bottom: 20px;
}
.public-facilities-data-entry {
	padding: 0px 30px;

	.hidden {
		display: none;
	}

	.booking-information {
		background: nth($featureColors, 2);
		color: white;
		margin: 20px 0px;
		font-size: 1rem;
		padding: 25px;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 20px;

		h3 {
			border-bottom: 1px solid rgba(255, 255, 255, 0.8);
			padding: 0px 5px 5px 5px;
			margin-bottom: 15px;
		}

		.entry {
			display: flex;
			margin: 10px 5px;
			align-items: center;

			div:nth-child(1) {
				font-weight: bold;
				flex-shrink: 0;
				width: 150px;
			}
			div:nth-child(2) {
				margin-left: 10px;
				word-break: break-word;
			}
		}
	}

	.booking-submit {
		background: nth($featureColors, 3);
		color: $textColor;
		text-transform: uppercase;
		border: none;
		color: white;
		margin-top: 50px;
		margin-bottom: 50px;
		width: 100%;
		font-size: 1.3rem;
		border-radius: 30px;
		padding: 15px;
		font-weight: bold;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		display: block;
		box-shadow: 0px 0px 20px transparentize(nth($featureColors, 1), 0.7);
	}

	.redirect-to-home {
		background: nth($featureColors, 3);
		color: white;
		text-transform: uppercase;
		padding: 15px 40px;
		border: none;
		border-radius: 30px;
		font-size: 1.2rem;
	}

	.btn-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 50px;
	}

	.error,
	.success {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.response-message-title h2 {
		color: white;
		padding: 10px 50px;
		font-size: 1.5rem;
		margin-bottom: 10px;
	}
	.response-message-text {
		color: white;
		font-size: 1.2rem;
		max-width: 400px;
		padding: 10px 50px;
		text-align: center;
	}
}
.public-facilities-selection {
	padding: 0px 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	h2 {
		max-width: 300px;
	}

	li {
		max-width: 300px;
		background: nth($featureColors, 3);
		margin: 20px 0px;
		border-radius: 20px;
		padding: 15px 40px;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: 0.15s ease-in-out;

		&:hover {
			opacity: 0.7;
		}

		& > div {
			margin-left: 20px;
		}

		.text {
			color: transparentize(white, 0.2);
			font-weight: 500;
		}

		.title {
			color: white;
			font-size: 1.2rem;
			font-weight: bold;
		}
	}
}

.public-facilities-list {
	@media (min-width: 720px) {
		margin-top: -50px !important;
	}

	.tab-wrapper {
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
	}
	.right-arrow {
		position: fixed;
		right: 10px;
		top: 50vh;
		transform: scaleY(2) rotate(180deg);
	}
	.left-arrow {
		position: fixed;
		left: 10px;
		top: 50vh;
		transform: scaleY(2);
	}
	.left-arrow,
	.right-arrow {
		transition: $fastEaseTransition;
		opacity: 1;
		z-index: 2;
		&.hidden {
			opacity: 0;
		}
	}
	.public-facilities-type {
		.list-wrapper {
			padding: 0px 20px;
			padding-bottom: 100px;
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;

			h2 {
				margin: 0px 30px;
				text-align: center;
				font-size: 1.5rem;
				font-weight: 500;
				color: #333333;
				margin-bottom: 20px;
			}

			h3 {
				margin-top: -25px;
				text-align: center;
				font-size: 1.3rem;
				font-weight: normal;
				color: nth($featureColors, 3);
			}

			.facility {
				background: nth($featureColors, 3);
				color: white;
				box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
				margin: 20px 0px;
				font-size: 1rem;
				padding: 25px;
				border-radius: 20px;

				transition: 0.15s all ease-in-out;

				&:not(.active) {
					cursor: pointer;
					&:hover {
						opacity: 0.8;
					}
				}

				.facility-location {
					display: block;
					font-size: 1rem;
					color: darken(white, 30);
					margin-bottom: 2px;
					padding: 0px 5px;
				}
				.facility-name {
					font-size: 1.5rem;
					font-weight: bold;
					padding: 0px 5px;
				}
				.facility-place,
				.facility-location-info {
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-line-clamp: 2;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					margin-top: 2px;
					color: transparentize($color: white, $amount: 0.1);
					padding: 0px 5px;
					font-size: 1rem;
					line-height: 1rem;

					& > div:nth-child(1) {
						display: none;
					}
				}
				.facility-icons {
					margin-top: 15px;
					padding: 0px 5px;
					svg {
						margin: 2.5px;
					}
				}

				h3 {
					display: none;
				}
				.additional-information {
					display: none;
				}
				.facility-icon {
					display: inline-block;
				}
				.icon-description {
					display: none;
				}
				.book-btn {
					display: none;
				}

				&.active {
					.facility-description {
						-webkit-line-clamp: 500;
					}
					.facility-icon {
						display: flex;
						align-items: center;
						margin: 5px 0px;
					}
					.icon-description {
						display: inline-block;
						margin-left: 10px;
					}

					.facility-place,
					.facility-location-info {
						display: flex;
						padding: 5px;
						align-items: center;
						word-break: break-word;
						& > div:nth-child(1) {
							display: inline-block;
							width: 150px;
							flex-shrink: 0;
						}

						& > div:nth-child(2) {
							margin-left: 10px;
							font-weight: bold;
							flex-shrink: 1;
						}
					}

					.additional-information {
						display: flex;
						padding: 5px;
						align-items: center;
						word-break: break-word;
						& > div:nth-child(1) {
							width: 150px;
							flex-shrink: 0;
						}
						& > div:nth-child(2) {
							margin-left: 10px;
							font-weight: bold;
							flex-shrink: 1;
						}
					}
					a {
						text-decoration: none;
						color: #e88760;
					}

					.btn-wrapper {
						display: flex;
						justify-content: center;
						width: 100%;
					}

					.book-btn {
						color: white;
						text-transform: uppercase;
						display: inline-block;
						border: 2px solid white;
						background: none;
						padding: 12px 70px;
						font-size: 1.2rem;
						border-radius: 25px;
						margin-top: 40px;
						margin-bottom: 20px;
						transition: 0.15s all ease-in-out;

						&:hover {
							background: white;
							color: nth($featureColors, 3);
						}
					}

					h3 {
						display: block;
						margin-top: 20px;
						color: white;
						text-align: left;
						font-size: 1.1rem;
						font-weight: bold;
						border-top: 1px solid rgba(255, 255, 255, 0.3);
						padding: 10px 5px;
					}
				}
			}
		}
	}
}
.date-prompt {
	p {
		margin: 5px;
		font-size: 1.4rem !important;
		text-align: center;

		&.date {
			font-weight: bold;
			color: nth($featureColors, 1);
			font-size: 1.6rem !important;
			margin: 10px 0px;
		}
	}
}

li.public-facilities-date-selection {
	text-align: center;
	display: flex;
	justify-content: center;
	margin-top: 40px;

	input[type="date"] {
		margin-top: 25px;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
		border: none;
		background: none;
		display: block;
		border-radius: 30px;
		background: nth($featureColors, 3);
		width: 100%;
		max-width: 250px;
		padding: 10px 0px;
		color: white;
		font-size: 1rem;
		text-align: center;
		&::-webkit-calendar-picker-indicator {
			display: none;
		}

		&::-webkit-inner-spin-button {
			/* display: none; <- Crashes Chrome on hover */
			-webkit-appearance: none;
			margin: 0;
		}
	}
}
