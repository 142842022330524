$textColor: #333333;
$featureColors: #0866b0, #064678, #052f52, #011e36, #011525;

$backgroundColor: white;

$headerBackground: linear-gradient(to right top, #012c50, #013d6c, #014f8a, #0461a9, #0974c9);
$headerColor: white;

$standardFormButton: #011e36;

$dividerColor: rgb(231, 231, 231);

$navBackground: white;
$activeNavItemBackground: rgb(230, 230, 230);

$departmentTextColor: white;

//News
$newsTextBg: #fbfbfb;

$standardEaseTransition: 0.3s all ease-in-out;
$fastEaseTransition: 0.15s all ease-in-out;

$standardLinearTransition: 0.3s all linear;
$fastLinearTransition: 0.15s all linear;

// OneDashTools
$h2Color: $textColor;
$h2BorderColor: #d4d5d5;
$baseEaseTransition: $fastEaseTransition;

// IOS Styles
$iosWhiteBackground: #f2f2f7;
$iosBlue: #007aff;
