@import "../../Style/colors.scss";

.app {
	.app-content-wrapper {
		display: flex;
		justify-content: center;

		@media (min-width: 1200px) {
			position: relative;
		}
	}

	.update-available {
		p {
			margin: 10px;
			font-size: 1.2rem;
			text-align: center !important;
		}
		.dialog-title {
			text-align: center !important;
		}
		.right {
			width: 100%;
		}
	}

	.app-content {
		position: relative;
		background: $backgroundColor;
		.app-bg {
			background: $backgroundColor;
			margin-top: 0px;
			height: 40px;
			width: 100%;
			position: absolute;
			top: -70px;
			z-index: 1;
			border-radius: 40px 40px 0px 0px;
		}
		& > div {
			margin-top: 35px;
			@media (min-width: 720px) {
				margin-top: 30px;
			}
		}
	}
}

.tab-wrapper {
	width: calc(100% - 60px);
	padding: 0px 30px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	.tab-name {
		border: none;
		font-size: 1.1rem;
		background: none;
		margin: 0px 10px;
		hyphens: auto;
		&.active {
			font-weight: bold;
		}
	}
}
.online-indicator {
	background: var(--success);
}
.offline-indicator {
	background: var(--invalid);
}
.offline-online-indicator {
	position: fixed;
	height: 30px;
	width: 100vw;
	font-weight: bold;
	margin-top: -30px;
	z-index: 1000;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: slideInOut 3s ease-in-out;
}

@keyframes slideInOut {
	0% {
		margin-top: -30px;
	}
	15% {
		margin-top: 0px;
	}
	85% {
		margin-top: 0px;
	}
	100% {
		margin-top: -30px;
	}
}

h1.title,
h2.title {
	@media (min-width: 720px) {
		font-size: 1.8rem !important;
		color: $textColor !important;
		text-align: center;
		width: 100%;
	}
}
@media (min-width: 720px) {
	*::-webkit-scrollbar {
		width: var(--scrollbar-width);
		height: var(--scrollbar-width);
	}
	* {
		scrollbar-width: var(--scrollbar-width);
		scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
	}
	*::-webkit-scrollbar-track {
		background: var(--scrollbar-bg);
	}
	*::-webkit-scrollbar-thumb {
		background-color: var(--scrollbar-thumb);
		border-radius: 6px;
		border: 3px solid var(--scrollbar-bg);
	}
}

@media (min-width: 1200px) {
	.app {
		.content-splitter {
			display: flex;

			& > div:last-of-type {
				overflow-x: hidden;
				height: calc(100vh - 80px);
				margin-top: 80px;
				overflow-y: auto;
				flex-grow: 1;
				border-right: 1px solid $dividerColor;
			}
		}

		.desktop-news {
			width: 50%;
			max-width: 450px;
			float: right;
			height: calc(100vh - 80px);
			margin-top: 80px;

			overflow: auto;
			padding: 0px 40px;
			h1 {
				text-align: center;
				margin-top: 80px;
				font-size: 1.5rem;
			}

			.tab-wrapper button {
				margin: 50px 0px 0px 0px;
			}

			.news .image-news .image {
				img {
					border-radius: 10px;
				}
			}
		}
		.content-splitter {
			display: flex;
			flex-direction: row-reverse;
		}
	}
}
