@import "../../Style/colors.scss";

.main-navigation-wrapper {
	width: 100vw;
	display: flex;
	justify-content: center;

	.text-zoom {
		background: $navBackground;
		max-width: 300px;
		width: 100%;
		height: 70px;
		position: fixed;
		bottom: 0px;
		border-top: 1px solid $dividerColor;
		border-right: 1px solid $dividerColor;
		border-left: 1px solid $dividerColor;
		border-radius: 35px 35px 0px 0px;
		display: flex;
		justify-content: space-between;
		padding: 0px 30px;
		align-items: center;
		transition: 0.1s all ease-in-out;

		&.active {
			bottom: 80px;
		}

		.font-size-indicator {
			display: flex;
			justify-content: space-between;
			flex-grow: 1;
			margin: 0px 10px;
			align-items: center;
			span {
				display: block;
				width: 10px;
				height: 10px;
				border-radius: 8px;
				border: 2px solid $dividerColor;

				&.active {
					background: nth($featureColors, 2);
					border: 2px solid nth($featureColors, 2);
				}
			}
		}

		button {
			border-radius: 25px;
			background: none;
			display: inline-block;
			border: none;
			font-size: 35px;
			line-height: 40px;
			width: 50px;
			height: 50px;
			color: white;
			background: nth($featureColors, 2);
		}
	}
}

.main-navigation {
	background: $navBackground;
	width: calc(100vw);
	height: 75px;
	display: flex;
	align-items: center;
	z-index: 2;
	position: fixed;
	bottom: 0;
	left: 0px;
	border-top: 1px solid $dividerColor;
	border-right: 1px solid $dividerColor;
	border-left: 1px solid $dividerColor;
	border-radius: 35px 35px 0px 0px;
	padding-bottom: 10px;
	ul {
		flex-grow: 1;
		display: flex;
		justify-content: space-evenly;
		li {
			transition: $fastEaseTransition;
			cursor: pointer;
			@media (min-width: 720px) {
				&:hover {
					opacity: 0.7;
				}
			}

			button,
			.nav-btn {
				display: block;
				border: 0px;
				padding: 10px 15px;
				display: flex;
				align-items: center;
				text-decoration: none;
				border-radius: 50px;
				transition: 0.3s background-color ease-in-out;
				background-color: transparent;
				color: white;

				&:focus {
					border: none;
					outline: none;
				}

				.nav-description {
					margin: 0px;

					display: inline-block;
					max-width: 0px;
					overflow: hidden;
					font-weight: bold;
					animation: activeNavItemFadeOut 0.3s ease-in-out 0s;
				}
				.nav-icon {
					transition: 0.3s fill ease-in-out;
					fill: nth($featureColors, 2);
				}

				&.active {
					background-color: nth($featureColors, 2);

					.nav-description {
						margin: 0px 10px;
						max-width: 75px;
						animation: activeNavItem 0.3s ease-in-out 0s;
					}

					.nav-icon {
						fill: white;
					}
				}
			}
		}
	}
}

@keyframes activeNavItem {
	0% {
		max-width: 0px;
	}

	100% {
		max-width: 75px;
	}
}

@keyframes activeNavItemFadeOut {
	0% {
		max-width: 75px;
	}

	100% {
		max-width: 0px;
	}
}

@media (min-width: 1200px) {
	.main-navigation ul {
		margin-left: 250px !important;
	}
}
@media (min-width: 720px) {
	.main-navigation {
		top: 0;
		height: 80px;
		border-radius: 0px;
		border-bottom: 1px solid $dividerColor;
	}

	.main-navigation ul li .nav-btn .nav-description {
		max-width: 90px;
		color: $textColor;
		font-size: 1.1rem;
		animation: none;
		cursor: pointer;
	}
	.main-navigation ul li .nav-btn.active .nav-description {
		animation: none;
	}
	.main-navigation ul li .nav-btn .nav-icon {
		display: none;
	}
	.main-navigation ul li .nav-btn {
		border-bottom: 2px solid transparent;
		border-radius: 0;
		&.active {
			border-bottom: 2px solid nth($featureColors, 2);
			background-color: var(--white);
		}
	}
	.main-navigation ul {
		margin-left: 200px;
		justify-content: flex-start;
		li {
			margin: 0px 20px;
		}
	}
	.header .vg-text img {
		width: 190px;
		position: fixed;
		top: 15px;
		left: 15px;
		right: auto;
		z-index: 5;
	}
	.header.header-collapsed {
		height: auto;
		padding-top: 80px;
	}
	.header .title {
		transition: none;
	}
	.tab-wrapper .tab-name.active {
		font-weight: bold;
	}
	.tab-wrapper .tab-name {
		font-size: 1.2rem;
		margin-top: 50px;
		cursor: pointer;
	}
}
