@import "../../Style/colors.scss";

.time-slot-selection {
	h2 {
		text-align: center;
		font-size: 1.5rem;
		font-weight: 500;
		color: #333333;
		margin-bottom: 18px;
	}

	h3 {
		font-size: 1.3rem;
		font-weight: 500;
		margin: 0px 50px;
		margin-top: 50%;
		text-align: center;
		p {
			margin: 10px 0px;
		}
	}
	.day {
		background: nth($featureColors, 2);
		width: calc(100% - 50px);
		max-width: 400px;
		color: white;
		margin: 7.5px 0px;
		border-radius: 10px;
		padding: 20px;
		border-left: 8px solid nth($featureColors, 3);

		transition: 0.3s all ease-in-out;

		&:not(.active) {
			cursor: pointer;
		}

		@media (min-width: 720px) {
			&:not(.active):hover {
				opacity: 0.7;
			}
		}

		.day-name {
			font-weight: bold;
			font-size: 1rem;
		}
		.day-description {
			font-size: 0.9rem;
			color: rgba(255, 255, 255, 0.9);
		}

		&.disabled {
			filter: grayscale(100%);
			opacity: 0.5;
			pointer-events: none;
		}

		h3,
		.day-time-slots {
			display: none;
		}

		&.active {
			h3 {
				display: block;
				margin-top: 20px;
				color: white;
				text-align: center;
				font-size: 1.1rem;
				font-weight: bold;
				border-top: 1px solid rgba(255, 255, 255, 0.3);
				padding: 20px 5px;
			}
			.day-time-slots {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				padding-bottom: 15px;

				.time-slot {
					background: nth($featureColors, 3);
					padding: 10px 0px;
					width: 80px;
					text-align: center;
					border-radius: 20px;
					margin: 2.5px;
					color: white;
					text-transform: uppercase;
					border: none;
					transition: 0.15s all ease-in-out;

					@media (min-width: 720px) {
						&:hover {
							opacity: 0.7;
						}
					}
				}
			}
		}
	}

	.week {
		margin: 0px 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.right-arrow {
		position: fixed;
		right: 10px;
		top: 50vh;
		transform: scaleY(2) rotate(180deg);
		@media (min-width: 1200px) {
			position: absolute;
			top: calc(50vh - 300px);
		}
	}
	.left-arrow {
		position: fixed;
		left: 10px;
		top: 50vh;
		transform: scaleY(2);
		@media (min-width: 1200px) {
			position: absolute;
			top: calc(50vh - 300px);
		}
	}
	.left-arrow,
	.right-arrow {
		transition: $fastEaseTransition;
		opacity: 1;
		z-index: 2;
		cursor: pointer;
		&.hidden {
			opacity: 0;
			cursor: default;
		}
	}

	.week-indicator {
		width: 100%;
		display: flex;
		justify-content: center;
		cursor: pointer;

		span {
			transition: 0.3s all ease-in-out;
			&:hover {
				opacity: 0.7;
			}
		}

		span {
			display: block;
			width: 12px;
			height: 12px;
			margin: 5px;
			border-radius: 12px;
			border: 2px solid $dividerColor;

			&.active {
				background: nth($featureColors, 2);
				border: 2px solid nth($featureColors, 2);
			}
		}
	}
}
