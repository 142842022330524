@import "../../Style/colors.scss";

.home {
	position: relative;

	.logo-link {
		position: fixed;
		height: 130px;
		width: 130px;
		bottom: -10px;
		right: -10px;
		transition: $fastEaseTransition;

		@media (min-width: 720px) {
			&:hover {
				opacity: 0.7;
			}
			position: absolute;
			top: -5px;
			right: 0;
		}
	}

	.home-title {
		text-align: center;
		font-size: 1.5rem;
		font-weight: 500;
		color: $textColor;
	}

	.home-selection-items {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 20px 40px 10px 40px;

		.home-selection-item {
			a {
				display: flex;
				align-items: center;
				text-decoration: none;
				color: inherit;
			}
			margin-top: 15px;
			max-width: 400px;
			padding-bottom: 15px;
			border-bottom: 1px solid $dividerColor;
			cursor: pointer;
			transition: $fastEaseTransition;
			&:hover {
				opacity: 0.7;
			}
		}
		.home-selection-item:last-of-type {
			border-bottom: none;
		}
		.selection-icon {
			display: inline-block;
			padding: 15px;
			margin-right: 15px;
			border-radius: 10px;
			box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4);
		}
		.selection-text {
			display: inline-block;
		}

		@each $current-color in $featureColors {
			$i: index($featureColors, $current-color);
			.home-selection-item:nth-of-type(#{$i}) {
				.selection-icon {
					background: $current-color;
				}
			}
		}
	}
}
