@import "../../Style/colors.scss";

.department-storno {
	width: 100%;
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}
.department-selection {
	width: 100%;
	.department-selection-title {
		margin: 0px 30px;
		text-align: center;
		font-size: 1.5rem;
		font-weight: 500;
		color: $textColor;
	}

	.department-list {
		margin: 20px 35px 40px 35px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.department-wrapper {
		transition: $fastEaseTransition;
		max-width: 400px;
		width: 100%;

		.category-list {
			border: 1.5px solid nth($featureColors, 2);
			border-top: none;
			margin: 0px 1rem;
			margin-top: -10px !important;
			border-radius: 0px 0px 15px 15px;
			padding: 0rem 1rem;
			margin-top: -2px;
			max-height: 0px;
			transform: scaleY(0);
			transform-origin: top;
			overflow: hidden;
			transition: 0.3s max-height ease-in-out, 0.3s padding ease-in-out, 0.1s transform ease-in-out 0.2s;

			.category {
				cursor: pointer;
				font-size: 1.1rem;
				margin: 10px 0px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border: 2px solid transparentize(nth($featureColors, 2), 0.2);
				border-radius: 1.5rem;
				padding: 5px 0px;
				.category-title {
					flex-shrink: 1;
					margin-left: 1.5rem;
					overflow-wrap: break-word;
					max-width: calc(100% - 60px);
				}

				@media (min-width: 720px) {
					&:hover {
						.category-icon {
							background: nth($featureColors, 2);
						}
					}
				}
			}

			.category-icon {
				width: 30px;
				height: 30px;
				margin: 5px;
				flex-shrink: 0;
				display: flex;
				align-items: center;
				transition: 0.2s background ease-in-out;
				justify-content: center;
				background: transparentize(nth($featureColors, 2), 0.5);
				border-radius: 25px;
				.icon {
					transform: rotate(180deg);
					margin-left: 3px;
				}
			}
		}

		&.active {
			.department-icon {
				transform: rotate(270deg);
			}
			.category-list {
				max-height: 800px;
				transform: scaleY(1);
				margin-top: 0px;
				padding: 1rem;
			}
		}
	}
	.department-wrapper.active .department:hover .department-icon {
		transform: rotate(180deg);
	}

	.department {
		cursor: pointer;
		max-width: calc(100vw - 30px);
		background: nth($featureColors, 2);
		color: $departmentTextColor;
		margin: 15px 0px 0px 0px;
		padding: 1rem 1rem 1rem 3rem;
		border-radius: 3rem;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (min-width: 720px) {
			&:hover {
				.department-icon {
					transform: rotate(270deg);
				}
			}
		}

		& > div {
			max-width: calc(100% - 60px);
		}

		.department-icon {
			width: 3rem;
			flex-shrink: 0;
			height: 3rem;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 2rem;
			background-color: transparentize(white, 0.8);
			transform: rotate(180deg);
			transition: 0.2s transform ease-in-out;
			.icon {
				margin-right: 5px;
			}
		}

		.department-title {
			font-weight: bold;
			overflow-wrap: break-word;
			font-size: 1.2rem;
		}
		.department-description {
			color: transparentize($departmentTextColor, 0.4);
			font-size: 0.9rem;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			width: 100%;
		}
	}
}

.department-booking-data-entry {
	padding: 0px 35px;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
	width: calc(100% - 70px);
	h2 {
		margin: 0px 30px;
		text-align: center;
		font-size: 1.5rem;
		font-weight: 500;
		color: #333333;
		margin-bottom: 20px;
	}

	.booking-information {
		background: nth($featureColors, 2);
		color: white;
		margin: 20px 0px;
		font-size: 1rem;
		padding: 25px;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 20px;
		p {
			line-height: 1.5rem;
			.bold {
				font-weight: bold;
			}
		}
		h3 {
			border-bottom: 1px solid rgba(255, 255, 255, 0.8);
			padding: 0px 5px 5px 5px;
			margin-bottom: 15px;
		}

		.entry {
			display: flex;
			margin: 10px 5px;
			align-items: center;

			div:nth-child(1) {
				font-weight: bold;
				flex-shrink: 0;
				width: 120px;
			}
			div:nth-child(2) {
				margin-left: 10px;
			}
		}
	}
	.important {
		background: #800404;
	}

	.booking-submit {
		background: nth($featureColors, 3);
		color: $textColor;
		text-transform: uppercase;
		border: none;
		color: white;
		margin-top: 50px;
		margin-bottom: 50px;
		width: 100%;
		font-size: 1.3rem;
		border-radius: 30px;
		padding: 15px;
		font-weight: bold;
		box-shadow: 0px 0px 20px transparentize(nth($featureColors, 1), 0.7);
	}
	.redirect-to-home {
		background: nth($featureColors, 3);
		color: white;
		text-transform: uppercase;
		padding: 15px 40px;
		border: none;
		border-radius: 30px;
		font-size: 1.2rem;
	}

	.btn-wrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 50px;
	}

	.error,
	.success {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.response-message-title h2 {
		color: white;
		padding: 10px 50px;
		font-size: 1.5rem;
		margin-bottom: 10px;
	}
	.response-message-text {
		color: white;
		font-size: 1.2rem;
		max-width: 400px;
		padding: 10px 50px;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}

	.hidden {
		display: none;
	}
}
