@import "../../Style/colors.scss";
.about {
	button,
	a {
		border: none;
		background: none;
		text-align: left;
		font-size: 1rem;
		text-decoration: none;
		word-break: break-all;
	}

	.about-items {
		max-width: 500px;
		width: 80%;
		margin-left: auto;
		margin-right: auto;
		.item {
			margin: 20px 0px;
			display: flex;
			align-items: center;
			.icon {
				margin-right: 20px;
				flex-shrink: 0;
			}
		}
		h2 {
			font-weight: normal;
			font-size: 1.3rem !important;
			color: $textColor;
			text-align: left;
			margin: 3px 0px;
		}
		.text {
			color: lighten($textColor, 30);
		}
	}

	.opening-entries {
		.entry {
			margin: 7.5px 0px;
			display: flex;

			span {
				display: inline-block;
			}
			span:first-of-type {
				width: 120px;
			}
		}
	}

	.ios-install-dialog {
		li {
			font-size: 1rem;
			line-height: 1.4rem;
		}
	}
}
