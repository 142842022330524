@import "../../Style/colors.scss";

@media (min-width: 720px) {
	body {
		background: $backgroundColor;
	}
}

.header {
	background: url("./vg-preview.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100vw;
	height: 22vh;
	min-height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.2s height ease-in-out 0.3s;
	position: relative;

	&.custom {
		height: 35vh;
		align-items: flex-end;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		.wrapper {
			padding: 0px 30px 60px 30px;
			display: block;

			.name {
				text-transform: uppercase;
				font-family: "Raleway";
				font-weight: 900;
				color: white;
				font-size: 40px;
				text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
				line-height: 32px;
				width: 70%;
			}
			.location {
				color: rgba(255, 255, 255, 0.8);
				text-align: left;
				margin: 0px;
				font-weight: bold;
				font-size: 17px;
				margin-top: 10px;
			}
		}
	}

	& > .wrapper {
		margin-top: -40px;
		display: flex;
		align-items: center;
		width: 100%;

		& > h1 {
			flex-grow: 1;
			display: block;
		}
	}

	.header-bg-overlay {
		background: rgba(0, 0, 0, 0.25);
		height: 100%;
		width: 100%;
		position: absolute;
	}
	.wrapper {
		z-index: 1;
	}

	@media (min-width: 720px) {
		height: 15vh;
		margin-top: 100px;
		justify-content: flex-start;
		&.collapsed {
			padding-top: 0px !important;
		}

		&.custom {
			margin-top: 0px;
		}
	}
	@media (min-width: 1200px) {
		margin-top: 0px;
	}

	.back-btn {
		z-index: 99;
		animation: backBtnFadeIn 0.6s ease-in;
		position: absolute;
		margin-left: 30px;
		top: 30px;
		left: 0px;
	}

	.vg-icon {
		position: absolute;
		top: 0px;
		left: 0px;
		img {
			width: 40px;
			position: absolute;
			top: 15px;
			left: 15px;
		}
	}
	.vg-text {
		position: absolute;
		top: 0px;
		right: 0px;
		img {
			width: 150px;
			position: absolute;
			top: 15px;
			right: 15px;
		}
	}

	&.collapsed {
		@media (max-width: 720px) {
			.title {
				font-size: 1.2rem;
			}
		}
		height: 120px;
	}
	@media (min-width: 720px) {
		background: none;
	}

	.title {
		transition: 0.2s font-size ease-in-out 0.3s;
		color: $headerColor;
		font-weight: 700;
		font-size: 1.8rem;
		text-align: center;
	}
}

@media (min-width: 1200px) {
	.header {
		width: 100%;
		margin-top: 70px;
	}
}
@keyframes backBtnFadeIn {
	0% {
		opacity: 0;
	}
	70% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
