/*********************************
			FORM
*********************************/

/*********************************
			Components
*********************************/
.style-vg-twenty {
	/*********************************
			GENERIC INPUT
	*********************************/
	&.onedash-form-component {
		padding: 10px 20px 10px 20px;
		margin: 15px 0px;
		max-width: var(--input-width);
		width: calc(100% - 40px);
		border-radius: var(--tools-border-radius);
		border: 2px solid transparent;
		margin-left: auto;
		margin-right: auto;
		transition: 0.3s all ease-in-out;

		&.input-invalid {
			border: 2px solid var(--invalid);
		}
		&.focused {
			box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
		}

		& .required {
			color: var(--invalid);
			font-size: 1.4rem;
			position: absolute;
			margin-top: -5px;
		}

		& > .onedash-label {
			color: var(-text);
			font-weight: bold;
			margin-left: 10px;
			font-size: calc(var(--tools-font) - 0.05rem);
		}

		& > .component {
			display: block;
			padding: 10px 12.5px;
			margin-top: 5px;
			width: calc(100% - 28px);
			font-weight: normal;
			font-size: var(--tools-font);
		}
		& > .read-only {
			display: block;
			padding: 12px 0px;
			margin: 0px;
			width: calc(100%);
			font-weight: normal;
			font-size: calc(var(--tools-font) + 0.1rem);
		}
		select {
			border: none;
		}
	}

	/*********************************
				BOOLEAN
	*********************************/
	&.onedash-boolean {
		margin-top: 21px;
		--duration: 0.4s;

		&.has-children {
			display: inline-flex;
			align-items: center;
		}

		&.read-only,
		&.disabled {
			pointer-events: none;
		}
		&.disabled {
			.check-bg {
				background: var(--light-grey);
				border-color: var(--grey) !important;

				&:after {
					background: var(--light-grey);
				}
			}
			.checkmark svg {
				background: var(--grey) !important;
			}
		}

		.onedash-children {
			flex-shrink: 1;
		}
		.onedash-label {
			display: block;
		}
		.checker {
			display: block;
			height: 31px;
			width: 55px;
			box-sizing: content-box;
			padding: 5px;
			border-radius: 4px;
			transition: transform var(--duration) ease;
			cursor: pointer;
			flex-shrink: 0;
			margin-right: 10px;
			margin-top: 5px;
			position: relative;
		}

		.checkmark {
			width: 25px;
			height: 25px;
			transition: transform var(--duration) ease;
			transform: translateX(-3px);
			position: relative;
			z-index: 5;

			svg {
				display: block;
				background: #e5e5e5;
				transform: translateX(7px);
				border-radius: 2.4px;
				margin-top: 3px;

				transition: background-color var(--duration) ease, transform calc(var(--duration) * 1.5) ease;

				path {
					// opacity: 0;
					stroke-dasharray: 90 90;
					stroke-dashoffset: 90;
					transition: stroke-dashoffset calc(var(--duration) / 3) linear calc(var(--duration) / 3);
				}
			}
		}

		.checkbox {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0.4;
			visibility: hidden;

			&:checked {
				~ .checkmark {
					transform: translate(32px);

					svg {
						background: var(--success);
						transform: translate(-6.4px);
						path {
							stroke-dashoffset: 0;
						}
					}
				}
			}
		}

		.check-bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: inherit;
			background: var(--white);
			border: 2px solid var(--grey);
			z-index: 2;
			box-sizing: border-box;

			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				border-radius: inherit;
			}

			&:after {
				background: var(--white);
			}

			&:focus {
				outline: none;
			}
		}

		.checker.checker {
			&:active {
				transform: scale(0.85);
				transition-duration: calc(var(--duration) / 2);

				.check-bg::before {
					transform: translateY(0) scale(0.8);
					opacity: 0.2;
				}
			}
		}
	}

	/*********************************
				INPUT
	*********************************/

	&.onedash-input {
		&.has-label {
			&.filled .search-icon {
				top: 36px;
			}
			.search-icon {
				top: 27px;
			}
		}
		&.filled .search-icon {
			cursor: pointer;
			top: 18px;
			&__circle {
				width: 20px;
				height: 0px;
				transform: rotate(-45deg);
				border: 1px solid var(--grey);
			}
			&__rectangle {
				width: 20px;
				height: 0px;
				border: 1px solid var(--grey);
				right: 2px;
				bottom: 5.75px;
			}

			&:hover {
				.search-icon__circle,
				.search-icon__rectangle {
					border-color: var(--invalid);
				}
			}
		}
		input {
			border: none;
			color: var(--text);
			transition: 0.15s all ease-in-out;
			background: var(--white);

			&[type="search"] {
				padding-right: 45px;
			}

			&[type="search"]::-webkit-search-decoration,
			&[type="search"]::-webkit-search-cancel-button,
			&[type="search"]::-webkit-search-results-button,
			&[type="search"]::-webkit-search-results-decoration {
				display: none;
			}

			&:focus {
				outline: none;
			}

			&:disabled {
				background: var(--light-grey);
				border-color: var(--grey) !important;
			}
		}

		.search-icon {
			width: 25px;
			margin: 0 auto;
			padding: 6.25px 0;
			text-align: center;
			position: absolute;
			top: 9px;
			right: 40px;
			transition: 0.15s all ease-in-out;

			&__circle {
				width: 12.5px;
				height: 12.5px;
				border: 2px solid var(--grey);
				border-radius: 12.5px;
				transition: 0.15s all ease-in-out;
			}

			&__rectangle {
				position: absolute;
				right: 0;
				bottom: 3.75px;
				width: 8.75px;
				transform: rotate(45deg);
				border: 1px solid var(--grey);
				border-top-right-radius: 2px;
				border-bottom-right-radius: 2px;
				transition: 0.15s all ease-in-out;
			}
		}
	}

	/*********************************
				DIALOG
	*********************************/
	&.onedash-dialog {
		display: none;
		&.dialog-visible {
			display: block;
		}

		&.no-padding {
			.dialog-wrapper {
				.dialog-content {
					max-height: 90vh !important;
				}
				.dialog {
					width: auto;
					top: -25px;
					background: none;
					padding: 0px;
					margin: 0px;
					box-shadow: none;
					.dialog-content {
						padding-bottom: 0px;
					}
				}
				.dialog-close > span {
					border: 1px solid var(--white) !important;
				}
			}
		}

		.dialog-bg {
			background: transparent;
			animation: dialogIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
			position: fixed;
			width: 100vw;
			height: 100vh;
			left: 0;
			top: 0;
			z-index: 50000;
		}

		&.prompt {
			.dialog-wrapper {
				.dialog {
					.dialog-title {
						border-bottom: none;
						font-weight: bold;
						margin-bottom: 0px;
					}
					.dialog-content {
						p {
							margin: 0px 0px 10px 0px;
						}
					}
				}
			}
		}

		.dialog-wrapper {
			position: fixed;
			z-index: 100000;
			width: 100vw;
			height: 100vh;
			left: 0;
			top: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			.dialog {
				background: var(--white);
				border-radius: 5px;
				padding: 15px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				position: relative;
				color: var(--text);
				width: calc(100% - 60px);
				animation: scaleIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
				margin-top: -100px;

				.dialog-title {
					text-transform: uppercase;
					margin: 0px;
					font-size: 1.6rem;
					text-align: left;
					width: 100%;
					color: var(--feat-1);
					margin-bottom: 10px;
					margin-top: 5px;
					padding-bottom: 10px;
					font-weight: bold;
				}
				.dialog-close {
					position: absolute;
					right: 30px;
					top: 25px;
					width: 20px;
					height: 20px;
					cursor: pointer;

					@media (max-width: 720px) {
						right: 20px;
						top: 22px;
					}
					& > span {
						margin-top: 10px;
						width: 20px;
						position: absolute;
						border: 1px solid var(--text);
						border-radius: 1px;
						display: block;
						transition: 0.15s transform ease-in-out;
						&:nth-child(1) {
							transform: rotate(45deg);
						}
						&:nth-child(2) {
							transform: rotate(-45deg);
						}
					}
					&:hover {
						& > span:nth-child(1) {
							transform: rotate(90deg);
						}
						& > span:nth-child(2) {
							transform: rotate(0deg);
						}
					}
				}

				.dialog-content {
					max-height: calc(90vh - 200px);
					overflow-y: auto;
					margin-bottom: 20px;

					&::-webkit-scrollbar {
						width: var(--scrollbar-width);
					}
					& {
						scrollbar-width: var(--scrollbar-width);
						scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-bg);
					}
					&::-webkit-scrollbar-track {
						background: var(--scrollbar-bg);
					}
					&::-webkit-scrollbar-thumb {
						background-color: var(--scrollbar-thumb);
						border-radius: 6px;
						border: 3px solid var(--scrollbar-bg);
					}

					h3 {
						color: var(--feat-2);
						font-size: 1.5rem;
						margin-top: 25px;
						margin-bottom: 0;

						&:first-of-type {
							margin-top: 0px;
						}
					}
					h4 {
						color: var(--feat-3);
						margin: 20px 0 0 0;
						font-size: 1.1rem;
					}
					p {
						font-size: 1rem;
						line-height: 1.3rem;
						margin: 7.5px 5px;
					}
					ul {
						padding-left: 20px;
						li {
							list-style-type: square;
							margin: 5px 0;
						}
					}
					.cookie-privacy-toggle {
						margin-left: 0px;
						max-width: calc(100% - 10px);
						padding-left: 0px;
						.onedash-children {
							margin-top: 5px;
						}
					}
				}

				.dialog-footer {
					display: flex;
					justify-content: space-between;
					margin-bottom: -10px;
					margin-top: -20px;
				}

				& > div:first-of-type {
					margin: 0px 10px;
				}

				.react-datepicker {
					border: none;
					background: none;
					.react-datepicker__day-name {
						font-size: 18px;
						margin: 0px;
						padding: 10px;
						width: 23px;
						line-height: 20px;
					}
					.react-datepicker__header {
						padding: 20px 30px 5px 30px;
						border-radius: 10px 10px 0px 0px;
						background: var(--feat-3);
						* {
							color: white;
						}
					}
					.selected-start-date {
						border-radius: 0.3rem;
						background-color: var(--feat-3);
						color: #fff;
					}
					.react-datepicker__day--selected {
						border-radius: 0.3rem;
						background-color: var(--invalid) !important;
						color: #fff;
					}
					.day-is-disabled {
						background-color: var(--disabledBtn) !important;
						pointer-events: none;
					}
					.react-datepicker__month {
						background: white;
						margin: 0px;
						padding: 10px 30px 20px 30px;
						border-radius: 0px 0px 10px 10px;
					}
					.react-datepicker__navigation {
						height: 30px;
						width: 30px;
						border: 15px solid transparent;
						right: 10px;
						border-left-color: #ccc;
						top: 30px;

						&.react-datepicker__navigation--previous {
							border-right-color: #ccc;
							border-left-color: transparent;
						}
					}
					.react-datepicker__day {
						font-size: 22px;
						margin: 0px;
						padding: 10px;
						width: 23px;
						line-height: 25px;
					}
					.react-datepicker__current-month {
						font-size: 24px;
						margin: 10px;
					}
				}
			}
		}

		&.dialog-closes {
			.dialog {
				animation: scaleDown 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
			}
			.dialog-bg {
				animation: dialogOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
			}
		}

		@keyframes scaleDown {
			0% {
				transform: scale(1);
				opacity: 1;
			}
			100% {
				transform: scale(0);
				opacity: 0.1;
			}
		}
		@keyframes scaleIn {
			0% {
				transform: scale(0);
				opacity: 0.1;
			}
			100% {
				transform: scale(1);
				opacity: 1;
			}
		}

		@keyframes dialogIn {
			0% {
				background: transparent;
			}
			100% {
				background: var(--dialog-bg);
			}
		}

		@keyframes dialogOut {
			0% {
				background: var(--dialog-bg);
			}
			100% {
				background: transparent;
			}
		}
	}

	/*********************************
				Buttons
	*********************************/
	&.onedash-button {
		margin: 10px;
		padding: 10px 12.5px;
		border-radius: var(--button-border-radius);
		border: 2px solid;
		font-size: calc(var(--font-size) + 0.1rem);
		transition: 0.15s all ease-in-out;
		cursor: pointer;
		user-select: none;
		width: calc(100% - 20px);
		&:focus {
			border-color: var(--black) !important;
			outline: none;
		}
		&.confirm-date {
			width: 100%;
			margin: 10px 0px;
			&.btn-primary {
				&:hover,
				&:focus {
					color: var(--white);
				}
			}
		}
		&.disabled {
			pointer-events: none;
			background: var(--light-grey) !important;
			border-color: var(--grey) !important;
			color: var(--text) !important;
		}

		&.btn-primary {
			background: var(--feat-3);
			border-color: var(--feat-3);
			color: var(--white);
			&:hover,
			&:focus {
				background: none;
				color: var(--text);
			}
		}
		&.btn-link {
			background: none;
			border-color: none;
			border: none;
			border-radius: 0px;
			margin: 0px;
			padding: 0px;
			display: inline;
			font-size: var(--font-size);
			width: auto;
			color: var(--blue);
			&:hover,
			&:focus {
				color: var(--text);
			}
		}
	}

	/*********************************
				POPOVER
	*********************************/

	&.onedash-popover-wrapper {
		&.isClosing {
			.popover-bg {
				animation: blurOut 0.32s ease-in-out;
			}
			.popover {
				animation: slideOut 0.3s ease-in-out;
			}
		}
		&.isMoving .popover {
			transition: 0.3s margin-bottom ease-in-out;
		}

		&.not-closeable {
			.popover {
				$padding: 30px;
				padding: 30px $padding $padding $padding;
			}
		}
		.btn-next {
			width: 100%;
			margin: 10px 0px;
		}

		.popover-touchbar {
			width: 50%;
			height: 5px;
			border-radius: 5px;
			background: rgba(var(--black-rgb), 0.1);
			position: absolute;
			left: 25%;
			margin-top: -20px;
		}
		a {
			text-decoration: none;
			color: var(--feat-1);
			font-size: 1rem;
		}
		.popover-bg {
			position: fixed;
			z-index: 99;
			background: rgba(0, 0, 0, 0.5);
			width: 100vw;
			height: 100vh;
			top: 0;
			left: 0;
			-webkit-backdrop-filter: blur(4px);
			animation: blurIn 0.3s ease-in-out;
		}

		.popover {
			$padding: 30px;
			$margin: 10px;
			max-height: 70vh;
			position: fixed;
			width: calc(100vw - #{$padding * 2 + $margin * 2});
			max-width: 380px;
			bottom: 0px;
			left: 0;
			border-radius: var(--tools-border-radius) var(--tools-border-radius) 0px 0px;
			padding: 40px $padding $padding $padding;
			background: var(--white);
			margin: 0px $margin;
			z-index: 100;
			animation: slideIn 0.3s ease-in-out;

			.popover-handle {
				width: 100px;
				height: 5px;
				border-radius: 5px;
				background: rgba(var(--black-rgb), 0.1);
				margin-left: auto;
				display: block;
				margin-right: auto;
				margin-bottom: 20px;
			}

			.popover-close {
				position: absolute;
				right: 30px;
				top: 40px;
				width: 35px;
				height: 35px;
				background: var(--light-grey);
				cursor: pointer;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				& > span {
					margin-top: 0px;
					width: 20px;
					position: absolute;
					border: 1px solid var(--grey);
					border-radius: 1px;
					display: block;
					transition: 0.15s transform ease-in-out;
					&:nth-child(1) {
						transform: rotate(45deg);
					}
					&:nth-child(2) {
						transform: rotate(-45deg);
					}
				}
				&:hover {
					& > span:nth-child(1) {
						transform: rotate(90deg);
					}
					& > span:nth-child(2) {
						transform: rotate(0deg);
					}
				}
			}

			.content {
				margin-top: -20px;
				color: var(--text);
				& > h2 {
					margin: 0px;
					margin-top: 20px;
					color: var(--feat-1);
					font-weight: bold;
					text-align: left;
					margin-bottom: 10px;

					padding: 0px 0px 2.5px 5px;
				}
				h2.wait-text {
					text-align: center;
				}
				.content-children {
					margin-top: 20px;
				}
				.onedash-form-component {
					margin: 5px 0px 10px 0px;
					padding: 5px;
					width: calc(100% - 10px);
					max-width: 100%;

					&.focused {
						box-shadow: none;
					}
					input {
						background: var(--light-grey);
						margin-top: 0px;
						width: calc(100% - 25px);
					}
				}
			}
		}

		@keyframes blurIn {
			0% {
				-webkit-backdrop-filter: blur(0px);
				background: rgba(0, 0, 0, 0);
			}
			100% {
				-webkit-backdrop-filter: blur(4px);
				background: rgba(0, 0, 0, 0.5);
			}
		}
		@keyframes blurOut {
			0% {
				-webkit-backdrop-filter: blur(4px);
				background: rgba(0, 0, 0, 0.5);
			}
			100% {
				-webkit-backdrop-filter: blur(0px);
				background: rgba(0, 0, 0, 0);
			}
		}
		@keyframes slideIn {
			0% {
				transform: translateY(50vh);
			}
			100% {
				transform: translateY(0vh);
			}
		}
		@keyframes slideOut {
			0% {
				transform: translateY(0vh);
			}
			100% {
				transform: translateY(50vh);
			}
		}
	}

	/*********************************
				SPINNER
	*********************************/
	&.spinner-container {
		display: none;

		&.visible {
			display: flex;
			justify-content: center;
			align-items: center;

			width: 100%;
			height: 100%;

			&.full-page {
				position: fixed;
				left: 0;
				top: 0;
				z-index: 200;
				width: 100vw;
				height: 100vh;

				.sk-cube:before {
					background-color: var(--whtie);
				}
			}
		}
		&.full-page {
			cursor: wait;
			.spinner-overlay {
				position: fixed;
				z-index: 201;
				background: rgba(0, 0, 0, 0.7);
				width: 100vw;
				height: 100vh;
				top: 0;
				left: 0;
				-webkit-backdrop-filter: blur(4px);
				-webkit-animation: blurIn 0.3s ease-in-out;
				animation: blurIn 0.3s ease-in-out;
			}
			.spinner {
				z-index: 202;
			}
		}
		.spinner {
			&.invisible {
				opacity: 0;
			}
			&.visible {
				opacity: 1;
			}
			transition: 0.3s all ease-in-out;
			margin: 20px auto;
			width: 40px;
			height: 40px;
			position: relative;
			transform: rotateZ(45deg);
			cursor: wait;
		}

		.sk-cube {
			float: left;
			width: 50%;
			height: 50%;
			position: relative;
			transform: scale(1.1);

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: var(--spinner-color);
				animation: sk-foldCubeAngle 2.4s infinite linear both;
				transform-origin: 100% 100%;
			}

			&.sk-cube2 {
				transform: scale(1.1) rotateZ(90deg);
				&:before {
					animation-delay: 0.3s;
				}
			}
			&.sk-cube3 {
				transform: scale(1.1) rotateZ(180deg);
				&:before {
					animation-delay: 0.6s;
				}
			}
			&.sk-cube4 {
				transform: scale(1.1) rotateZ(270deg);
				&:before {
					animation-delay: 0.9s;
				}
			}
		}

		@keyframes sk-foldCubeAngle {
			0%,
			10% {
				-webkit-transform: perspective(140px) rotateX(-180deg);
				transform: perspective(140px) rotateX(-180deg);
				opacity: 0;
			}
			25%,
			75% {
				-webkit-transform: perspective(140px) rotateX(0deg);
				transform: perspective(140px) rotateX(0deg);
				opacity: 1;
			}
			90%,
			100% {
				-webkit-transform: perspective(140px) rotateY(180deg);
				transform: perspective(140px) rotateY(180deg);
				opacity: 0;
			}
		}

		@keyframes blurIn {
			0% {
				-webkit-backdrop-filter: blur(0px);
				background: rgba(0, 0, 0, 0);
			}
			100% {
				-webkit-backdrop-filter: blur(4px);
				background: rgba(0, 0, 0, 0.5);
			}
		}
		@keyframes blurOut {
			0% {
				-webkit-backdrop-filter: blur(4px);
				background: rgba(0, 0, 0, 0.5);
			}
			100% {
				-webkit-backdrop-filter: blur(0px);
				background: rgba(0, 0, 0, 0);
			}
		}
	}
}
.style-light-grey {
	&.onedash-form-component {
		margin: 5px 0px;
		padding: 5px;
		width: calc(100% - 10px);
		max-width: 100%;
		position: relative;

		& > .component {
			display: block;
			padding: 10px 12.5px;
			width: calc(100% - 10px);
			font-weight: normal;
			font-size: var(--tools-font);
		}
		input {
			border: none;
			color: var(--text);
			transition: 0.15s all ease-in-out;
			background: var(--light-grey);
			margin-top: 0px;
			width: calc(100% - 25px);
			border-radius: 25px;

			&[type="search"] {
				padding-left: 55px;
				padding-right: 20px;
			}

			&[type="search"]::-webkit-search-decoration,
			&[type="search"]::-webkit-search-cancel-button,
			&[type="search"]::-webkit-search-results-button,
			&[type="search"]::-webkit-search-results-decoration {
				display: none;
			}

			&:focus {
				outline: none;
			}

			&:disabled {
				background: var(--light-grey);
				border-color: var(--grey) !important;
			}
		}
		.search-icon {
			width: 25px;
			margin: 0 auto;
			padding: 6.25px 0;
			text-align: center;
			position: absolute;
			top: 7px;
			left: 20px;
			transition: 0.15s all ease-in-out;

			&__circle {
				width: 12.5px;
				height: 12.5px;
				border: 2px solid var(--grey);
				border-radius: 12.5px;
				transition: 0.15s all ease-in-out;
			}

			&__rectangle {
				position: absolute;
				right: 0;
				bottom: 3.75px;
				width: 8.75px;
				transform: rotate(45deg);
				border: 1px solid var(--grey);
				border-top-right-radius: 2px;
				border-bottom-right-radius: 2px;
				transition: 0.15s all ease-in-out;
			}
		}
	}
}
