@import "../../Style/colors.scss";

.news {
	.additional-link {
		background: var(--red);
		font-weight: bold;
		font-size: 1.2rem;
		color: white;
		text-align: center;
		margin: 0px 0px 20px 0px;
		padding: 10px 0px;

		@media (min-width: 1200px) {
			margin-top: 20px;
			width: calc(100% + 80px);
			margin-left: -40px;
		}
	}
	.load-more {
		background: none;
		border: none;
		width: 150px;
		display: block;
		margin-left: auto;
		margin-right: auto;
		padding: 30px;
		&:focus {
			outline: none;
		}
	}

	a {
		color: $textColor;
		text-decoration: none;
		margin: 20px 30px;
		display: block;
		border-bottom: 1px solid $dividerColor;
		padding-bottom: 20px;

		&:last-of-type {
			border-bottom: none;
		}

		.title {
			font-weight: bold;
			font-size: 1.3rem;
		}

		.month {
			color: lighten($textColor, 55);
		}
	}
	.single-news,
	.image-news {
		transition: 0.15s all ease-in-out;

		@media (min-width: 720px) {
			&:hover {
				opacity: 0.8;
			}
		}
	}
	.image-news {
		border-bottom: none;
		.news-text {
			background: $newsTextBg;
			border-radius: 10px;
			padding: 20px;
			margin-left: 50px;
			margin-top: -80px;
		}
		.title {
			min-height: 70px;
			margin-left: 40px;
			margin-bottom: 5px;
		}
		.image {
			width: 100px;
			img {
				width: 100%;
				border-radius: 20px;
			}
		}
		.text {
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
